import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import classnames from "classnames/bind";
import { ERROR_ORIGIN } from "../../ErrorAlert/constants";
import {
  UPDATE_SAVED_PRODUCT,
  REMOVE_SAVED_PRODUCT
} from "../../../state/modules/savedItems/constants";
import { saveProductForLater } from "../../../client/saveForLater";
import { removeProduct } from "../../../client/removeProduct";
import Icon from "../../Icon";
import styles from "./SaveForLater.css";
import { getAriaLabelKey } from "./getAriaLabelKey";
import logger from "../../../lib/logger";

const cx = classnames.bind(styles);
const VALENTINES = "valentines";

const SaveForLater = ({
  productId,
  price,
  reducedPrice,
  colourWayId,
  analyticsSaveForLater,
  saveProductInState,
  updateSavedProductInState,
  removeSavedProduct,
  removeFromSummary,
  setError,
  isSaved,
  formatTranslation,
  eventIconType,
  carouselCatId,
  brandName,
  colour,
  productCode,
  description,
  onWishlistBeacon,
  savedItemId,
  className
}) => {
  const [animationFinishedCount, setAnimationFinishedCount] = useState(0);
  const [showFlutterAnimationIcons, setShowFlutterAnimationIcons] = useState(
    false
  );
  const [isButtonEnabled, setIsButtonEnabled] = useState(true);
  const flutter = useRef([]);
  const [shouldTrack, setShouldTrack] = useState(true);

  const handleSave = () => {
    saveProductInState({ productId });
    setIsButtonEnabled(false);
    saveProductForLater({
      productId,
      colourWayId
    })
      .then(
        ({
          data: {
            savedItemIds: [{ id }]
          }
        }) => {
          updateSavedProductInState({ productId, id });
        }
      )
      .catch(error => {
        removeSavedProduct(productId);
        logger.error(error);

        if (!isSaved) {
          setError({
            message: error.message,
            actionType: UPDATE_SAVED_PRODUCT,
            errorOrigin: ERROR_ORIGIN.saveItem
          });
        }
      })
      .finally(() => setIsButtonEnabled(true));

    if (shouldTrack) {
      analyticsSaveForLater({
        productId,
        reducedPrice,
        price,
        carouselCatId,
        brandName,
        colour,
        productCode,
        description
      });

      if (onWishlistBeacon) {
        navigator.sendBeacon(onWishlistBeacon);
      }

      setShouldTrack(false);
    }

    setAnimationFinishedCount(0);
    setShowFlutterAnimationIcons(true);
    move();
  };

  const handleRemove = () => {
    setIsButtonEnabled(false);
    removeProduct(savedItemId)
      .then(() => {
        removeSavedProduct(productId);
        removeFromSummary(productId);
      })
      .catch(error => {
        logger.error(error);
        setError({
          message: error.message,
          actionType: REMOVE_SAVED_PRODUCT,
          errorOrigin: ERROR_ORIGIN.saveItem
        });
      })
      .finally(() => {
        setIsButtonEnabled(true);
      });
  };

  const toggleSave = () => (isSaved ? handleRemove : handleSave)();

  const handleAnimationEnd = () => {
    if (animationFinishedCount === flutter.current.length - 1) {
      setShowFlutterAnimationIcons(false);
      setAnimationFinishedCount(0);
    } else {
      setAnimationFinishedCount(animationFinishedCount + 1);
    }
  };

  const move = () => {
    const celebrationStyle = classnames(styles.flutter, {
      [styles[eventIconType]]: eventIconType
    });

    flutter.current.forEach(heart => {
      heart.className = celebrationStyle;
    });

    if (eventIconType === VALENTINES) {
      return;
    }

    const directionX = Math.random() > 0.5 ? -1 : 1;
    const directionY = Math.random() > 0.5 ? -1 : 1;
    const distance = eventIconType ? 60 : 40;

    flutter.current.forEach(heart => {
      heart.style.setProperty(
        "--translateX",
        Math.random() * distance * directionX
      );
      heart.style.setProperty(
        "--translateY",
        Math.random() * distance * directionY
      );
    });
  };

  const flutterCount = eventIconType === VALENTINES ? 5 : 2;

  useEffect(() => {
    if (isSaved && showFlutterAnimationIcons) {
      move();
    } else {
      flutter.current = [];
    }
  }, [isSaved, showFlutterAnimationIcons, flutterCount]);

  return (
    <button
      type="button"
      className={cx(styles.saveForLater, className, {
        [styles.saved]: isSaved,
        [styles.animated]: showFlutterAnimationIcons
      })}
      disabled={!isButtonEnabled}
      onClick={toggleSave}
      aria-label={formatTranslation(getAriaLabelKey({ isSaved }))}
      aria-pressed={isSaved}
    >
      <Icon icon="heart" className={styles.icon} />
      {isSaved &&
        showFlutterAnimationIcons &&
        [...new Array(flutterCount)].map((_, flutterIndex) => (
          <span
            key={flutterIndex}
            onAnimationEnd={handleAnimationEnd}
            ref={node => (flutter.current[flutterIndex] = node)}
          />
        ))}
    </button>
  );
};

SaveForLater.propTypes = {
  isSaved: PropTypes.bool.isRequired,
  productId: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  reducedPrice: PropTypes.number,
  colourWayId: PropTypes.number.isRequired,
  formatTranslation: PropTypes.func,
  saveProductInState: PropTypes.func.isRequired,
  updateSavedProductInState: PropTypes.func.isRequired,
  removeSavedProduct: PropTypes.func.isRequired,
  removeFromSummary: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  analyticsSaveForLater: PropTypes.func.isRequired,
  eventIconType: PropTypes.string,
  carouselCatId: PropTypes.number,
  brandName: PropTypes.string,
  colour: PropTypes.string,
  productCode: PropTypes.number,
  description: PropTypes.string,
  onWishlistBeacon: PropTypes.string,
  savedItemId: PropTypes.string,
  className: PropTypes.string
};

export default SaveForLater;
